.nav-bar-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 64px;
  background: #fff;
}

.company-name-logo{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 6px;
  color: #141414;
    font-family: DM Sans;
    font-size: 24px;
    font-weight: 600;
    line-height: 150%;
    text-decoration: none !important;
    padding-left: 8px;
}

.no-decoration-brand{
  text-decoration: none;
  display: flex;
  gap: 4px;
  align-items: center;
}

.no-decoration-brand img {
  height: 30px;
  margin-bottom: 0px;
}

.menu-icon span {
  color: #3575E2;
  font-size: 28px;
  /* font-weight: bold; */
}

@media only screen and (max-width: 768px) {
  .nav-bar-logo pre {
    font-size: 10px; /* adjust this value as needed */
  }
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 64px;
  background-color: #fff;
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
  position: relative;
}

.nav-links {
  display: flex;
  gap: 20px;
  align-items: center;
  font-size: 16px;
    font-style: normal;
    font-weight: 500;
}

.nav-links li {
  border-radius: 6px;
  padding: 8px 20px !important;
  cursor: pointer;
}

.nav-links li:first-child {
  border-radius: 6px;
  border: 1px solid rgba(20, 20, 20, 0.1);
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
  padding: 8px 20px !important;
  cursor: pointer;
}

.nav-links li:first-child:hover {
  border-radius: 6px;
    border: 1px solid #3575E2;
    background: #3575E2;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
    padding: 8px 20px !important;
    cursor: pointer;
    color: white;
}

.nav-links li:nth-child(2) {
  border-radius: 6px;
  border: 1px solid #3575E2;
  background: #3575E2;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
  padding: 8px 20px !important;
  cursor: pointer;
  color: white;
}

.nav-links li:hover {
}

.menu-icon {
  display: none;
  /* Initially hide the menu icon */
  cursor: pointer;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #141414;
  margin: 4px 0;
  transition: 0.4s;
}

ul{
  list-style: none;
}

@media (max-width: 768px) {
  .navbar {
    padding: 8px 0px;
    padding: 3%;
  }
  .menu-icon {
    display: block;
    /* Display the menu icon on small screens */
  }

  .nav-links {
    display: none;
    /* Hide the menu items by default */
    flex-direction: column;
    position: absolute;
    top: 70px;
    right: 20px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .nav-links.active {
    display: flex;
    /* Show the menu items when the menu is active */
  }
}
