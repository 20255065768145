.filter-values {
    display: grid;
    grid-template-rows: auto;
    gap: 8px;
}

.filter-values div {
    display: flex;
    gap: 6px;
    align-items: center;
}

.label-value {
    color: #393f47;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 24px */
}

/* Style the checkbox input */
input[type="checkbox"] {
    display: none;
    /* Hide the default checkbox */
}

/* Style the custom checkbox container for unchecked state */
input[type="checkbox"]+label {
    display: inline-block;
    position: relative;
    width: 16px;
    height: 16px;
    border: 1px solid rgba(20, 20, 20, 0.3);
    border-radius: 50%;
    cursor: pointer;
}

/* Style the custom checkbox container for checked state */
input[type="checkbox"]:checked+label {
    display: inline-block;
    position: relative;
    width: 16px;
    height: 16px;
    background-color: #0077ff;
    border: 1px solid #0077ff;
    border-radius: 50%;
}

/* Style the white div when the checkbox is checked */
input[type="checkbox"]:checked+label::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    background-color: white;
    border-radius: 50%;
}