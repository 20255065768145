body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f6f6ef; */
  font-family:'DM Sans',
    sans-serif;
    background-color: white;
}

code {
  font-family: 'DM Sans',
    sans-serif;
}