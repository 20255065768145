.home-main-headling-container{
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.home-main-headling {
  color: #141414;
  font-family: DM Sans;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  /* 84px */
}

.home-main-headling .blue-patch {
    color: #3575E2;
    font-family: DM Sans;
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
}

.home-main-sub-heading{
    color: rgba(0, 0, 0, 0.70);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

@media (max-width: 768px) {
    .home-main-headling, .home-main-sub-heading{
        padding: 2.8%;
      }
      
}