/* Overall Card Style */
.card {
    display: flex;
    flex-direction: row;
    align-items: center; /* Centers children vertically */
    gap: 24px;
    padding: 24px;
    border-radius: 8px;
    border: 1px solid rgba(20, 20, 20, 0.05);
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
    transition: transform 0.2s; /* Smooth transition */
    max-width: 100%;  /* Ensures card doesn't exceed viewport width */
    overflow: hidden; /* In case any child content wants to overflow */

}

.card:hover {
    transform: translateY(-5px); /* Move the card slightly up when hovered */
}

/* Location and Time */
.post-specs .location-time-wrapper {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    gap: 0.5rem; /* Adds a small vertical space between the items */
    align-items: flex-start; /* Align content to the start */
    white-space: nowrap; /* Keeps content on the same line ONLY for mobile */

}

.partition-circle {
    display: none; /* Since we're separating the items, we can hide the partition circle */
}

/* Logo */
.job-logo img {
    width: 50px; /* Adjust as necessary */
    border-radius: 8px; /* Rounded corners for the logo */
}

.jobLink {
    text-decoration: none;
    color: inherit;
    font-size: 1rem; /* Updated font-size for job details */
}

.job-logo {
    margin-right: 1rem; /* Adjust this value as per your preference for space */
}

@media (max-width: 768px) {
    .card {
        margin-right: 1rem;  /* Use px or rem instead of percentages */
        margin-left: 1rem;   /* Use px or rem instead of percentages */
        padding: 10px;
        gap: 10px; /* Reduced gap for mobile */
    }

    .jobLink {
        font-size: 0.875rem; /* Reduce font size for mobile */
        margin-top: 0.5rem; /* Add some top margin to separate from the logo */
    }

    .job-logo img {
        margin-top: 40px;
        width: 40px; /* Reduced logo size for mobile */
    }

    .icon-text-wrapper {
        display: flex;
        align-items: center; 
    }
}

* {
    box-sizing: border-box;
}