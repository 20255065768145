/* src/views/JobPage.css */

/* General Styles */
.job-page {
    font-size: 15px;
    max-width: 980px;
    margin: 0 auto;
    padding: 0 10px;
    min-height: 100vh;
    overflow-x: hidden;
}

/* Job Details */
.job-details-main {
    margin-top: 40px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    align-items: center;

}

img {
    max-width: 150px;
    margin-bottom: 20px;
    border-radius: 5px;
    object-fit: scale-down;
    margin-bottom: 20px;
    align-items: center;
}

.job-logo {

    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* This ensures vertical centering. Adjust as necessary. */
    width: 100%;
    align-items: center;

}

.job-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.job-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #333;
}

p {
    font-size: 16px;
    color: #555;
    line-height: 1.5;
    margin-bottom: 15px;
}

/* Apply Button */
.apply-button {
    padding: 10px 25px;
    background-color: #007BFF;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    margin-top: 20px;
    align-self: center;
    transition: background-color 0.3s ease;
}

.apply-button:hover {
    background-color: #0056b3;
}

/* Popup Styles */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup-content {
    width: 80%;
    max-width: 400px;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    text-align: center;
}

.close-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}

.popup-content input[type="email"] {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.popup-content input[type="email"]:hover,
.popup-content input[type="email"]:focus {
    border-color: #007BFF;
    outline: none;
}

.subscribe-button {
    padding: 10px 25px;
    background-color: #007BFF;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    margin-top: 20px;
    align-self: center;
    transition: background-color 0.3s ease;
}

.subscribe-button:hover {
    background-color: #0056b3;
}

.recruiters-section {
    background-color: #fffcf0;
    border: 2px dashed #f7c873;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
    position: relative;
    margin-top: 2%;
}

.recruiters-section::before {
    content: "🌟";
    font-size: 30px;
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fffcf0;
    padding: 0 5px;
}

.recruiters-section h4 {
    font-weight: bold;
    margin-bottom: 10px;
    color: #f78c35;
}

.recruiters-section p {
    font-style: italic;
    margin-bottom: 10px;
}

.recruiters-section ul {
    list-style-type: none;
    padding-left: 0;
}

.recruiters-section li {
    padding: 5px 0;
}

.unlock-button {
    display: inline-block;
    background-color: #f78c35;
    color: white;
    padding: 8px 15px;
    margin-top: 10px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.unlock-button:hover {
    background-color: #e5741b;
}

/* Input styling */
.recruiters-section input[type="email"] {
    width: 100%;
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 10px;
    font-size: 16px;
    box-sizing: border-box;  /* Ensure padding doesn't increase width */
    transition: border 0.3s ease;  /* Smooth transition for hover effect */
}

.recruiters-section input[type="email"]:hover,
.recruiters-section input[type="email"]:focus {
    border: 1px solid #555;  /* Darker border on hover/focus */
    outline: none;  /* Remove default focus outline */
}

/* Button styling */
.unlock-button {
    display: block;  /* Will make the button take the full width of its container */
    width: 50%;  /* Set to desired width */
    margin: 10px auto;  /* Centers the button */
    background-color: #f78c35;
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;  /* Smooth transition for hover effect */
}

.unlock-button:hover {
    background-color: #e5741b;
}

.interview-questions-section {
    background-color: #f5f5f5;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
    position: relative;
}

.interview-questions-section h4 {
    font-weight: bold;
    margin-bottom: 10px;
    color: #007BFF;
}

.interview-questions-section ul {
    list-style-type: none;
    padding-left: 0;
}

.interview-questions-section li {
    padding: 5px 0;
}

@media only screen and (max-width: 768px) {
    .job-details-main {
        margin-top: 20px;
        padding: 10px;
    }

    .job-title {
        font-size: 20px;
    }

    p {
        font-size: 14px;
    }

    .apply-button,
    .subscribe-button,
    .unlock-button {
        padding: 8px 20px;
        font-size: 14px;
    }

    .popup-content {
        max-width: 300px;
    }

    .recruiters-section h4,
    .interview-questions-section h4 {
        font-size: 16px;
    }
}

.apply-button:focus,
.subscribe-button:focus,
.unlock-button:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.interview-questions-section {
    background-color: #f2f2f7;
    border-radius: 5px;
    padding: 20px;
    margin: 20px 0;
    position: relative;
}

.star-icon {
    font-size: 24px;
    margin-right: 10px;
}

.interview-questions-section h4 {
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
}

.question-card {
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    padding: 15px;
    margin-bottom: 10px;
    position: relative;
    width: 100%; /* or whatever maximum width you desire */
    min-height: 50px; /* the minimum height you want your card to start with */
    box-sizing: border-box; /* ensures padding and border are included in the total width/height */
    padding: 10px; /* gives some space inside the card */
    word-wrap: break-word; /* allows long words to break and wrap onto the next line */
    border: 1px solid #ccc; /* just for visual clarity */
    margin-bottom: 10px; /* space between each card */
    max-height: 200px; /* or whatever max height you desire */
    overflow-y: auto; /* makes content scrollable if it overflows the max height */
    text-overflow: ellipsis
}

/* Additional styles to enhance look & feel */
.question-card:hover {
    background-color: #f7f7fa;
}

.question-card p {
    word-wrap: break-word;     /* Breaks the long words and wraps them onto the next line */
    overflow: hidden;          /* Ensures any overflowing text is hidden */
    text-overflow: ellipsis;   /* Adds '...' at the end for overflowed content */
    max-width: 100%;           /* Ensures the content does not overflow the parent */
    margin: 1%;
}

.posted-date {
    /* font-size: 0.8rem; */
}

.error-message {
    color: red;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid red;
    border-radius: 5px;
  }
  
  .job-logo img {
    max-width: 100%;
    max-height: 100%;
    display: block; /* Removes any default spacing underneath the image */
}
