/* src/views/HomePage.css */



.headline-container {
  padding: 80px 64px 0px;
}

.ascii-header {
  font-family: "DM Sans", sans-serif;
  margin-bottom: 30px;
}

.jobs-list ul {
  padding-left: 0;
  background-color: #fff;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #eee;
  border-radius: 4px;
}

.jobs-list {
  list-style-type: none;
  padding-left: 0;
}

/* Pagination styles */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  background-color: white;
  border: 1px solid #dee2e6;
  color: #495057;
  text-align: center;
  vertical-align: middle;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  margin: 0 5px;
  transition: background-color 0.2s, color 0.2s;
}

.pagination button:hover {
  background-color: #495057;
  color: white;
  cursor: pointer;
}

.pagination button.active {
  font-weight: bold;
  background-color: #343a40;
  color: white;
}

.pagination button:disabled {
  background-color: #e9ecef;
  color: #adb5bd;
  cursor: not-allowed;
}

/* Styling specifically for Next and Previous buttons */
.pagination button.next {
  padding: 0.375rem 1rem;
  font-weight: bold;
}

.pagination button {
  background-color: #fff;
  color: #007bff; /* primary color for text */
  border: 1px solid #007bff; /* primary color for border */
}

.pagination button:hover {
  background-color: #007bff;
  color: #fff;
}

.pagination button:disabled {
  background-color: #e0e0e0;
  color: #aaa;
}

.pagination button.active {
  background-color: #ffc107; /* secondary color for active page */
  color: #333;
}

.search-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-box input {
  border: 1px solid #007bff; /* Using the primary color we discussed */
  flex-grow: 1; /* Allow input to take maximum space */
}

.search-box button {
  background-color: #007bff; /* Using the primary color we discussed */
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.search-box button:hover {
  background-color: #007bff; /* Darker shade of the primary color */
}

.select__control {
  border: 1px solid #ccc !important;
  border-radius: 5px;
  padding: 5px;
  width: 200px;
  box-shadow: none !important;
}

.select__multi-value {
  background-color: #0077cc;
  border-radius: 5px;
  color: white;
  margin: 5px 5px 5px 0;
}

.select__multi-value__label {
  color: white;
  padding: 5px;
}

.select__multi-value__remove {
  color: white;
  border: none;
  cursor: pointer;
  margin: 0 5px;
}

.select-wrapper {
  flex: 1;
  min-width: 250px; /* This ensures that the dropdowns don't become too small */
}

.select-wrapper label {
  display: block;
  margin-bottom: 8px;
}

@media (max-width: 768px) {
  .filter-section {
    flex-direction: column;
    gap: 10px;
  }
}

.filter-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px; /* Adds space between the dropdowns */
}

.filter-section {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 20px;
}

.select-wrapper {
  flex: 1;
  margin: 0 10px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1); /* Add subtle shadows */
  background-color: #fff;
  transition: box-shadow 0.3s ease; /* Transition for smooth effect */
}

.select-wrapper:hover {
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.12);
}

.select-wrapper label {
  display: block;
  font-weight: 600; /* Make label bold */
  margin-bottom: 10px;
  font-size: 0.9em;
  color: #333;
}

/* Override React-Select's styles */
.select-wrapper .react-select__control {
  border-radius: 5px;
  border: none;
  box-shadow: none;
  transition: border 0.2s ease, box-shadow 0.2s ease;
}

.select-wrapper .react-select__control:hover {
  border-color: #0077ff; /* Some sample color for hover */
}

.select-wrapper .react-select__control--is-focused {
  border-color: #0077ff;
  box-shadow: 0 0 0 1px #0077ff;
}

.select-wrapper .react-select__indicator-separator {
  display: none;
}

.search-box {
  margin-top: 2%;
  display: flex;
  justify-content: center;
}

.search-box input {
  padding: 3%;
  border: 1px solid #e0e0e0;
  transition: border 0.2s ease;
}

.search-box input:focus {
  border-color: #0077ff;
}

.search-box button {
  border: none;
  border-radius: 0 5px 5px 0;
  background-color: #0077ff;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.search-box button:hover {
  background-color: #0055cc;
}

/* search and filter section start */

.filter-container {
  padding: 40px 64px;
}

.filter-wrapper {
  display: grid;
  grid-template-columns: 59% 30% 11%;
}

.search-filter {
  /* width: 100%; */
  /* border-radius: 4px 0px 0px 4px; */
  /* border: 1px solid rgba(20, 20, 20, 0.1); */
  /* background: #fff; */
  display: flex;
  /* padding: 18px; */
}

.search-filter-input {
  flex: 1;
  border: none;
  outline: none;
  padding: 0;
  color: rgba(20, 20, 20, 0.5);
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 24px */
}

.search-icon {
  display: flex;
  align-items: center;
  padding: 0 12px;
  /* Adjust the padding to position the icon properly */
  background: #fff;
  color: rgba(20, 20, 20, 0.5);
  /* Background color to match the input box */
}

.location-filter {
  width: 100%;
  border: 1px solid rgba(20, 20, 20, 0.1);
  background: #fff;
  display: flex;
  padding: 18px;
}

.location-filter-input {
  flex: 1;
  border: none;
  outline: none;
  padding: 0;
  color: rgba(20, 20, 20, 0.5);
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.filter-submit {
  display: flex;
  padding: 18px;
  justify-content: center;
  align-items: center;
  border-radius: 0px 4px 4px 0px;
  background: #3575e2;
  cursor: pointer;
  color: white;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

/* search and filter section end */

.main-section-container {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 32px;
  padding: 48px 64px;
}

.common-filters-wrapper {
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid rgba(20, 20, 20, 0.05);
}

.jobs-list-container {
  display: grid;
  grid-template-rows: auto;
  gap: 16px;
}


.email-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.subscription-container {
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid rgba(20, 20, 20, 0.05);
}
.resume-container {
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid rgba(20, 20, 20, 0.05);
}

.job-logo {
  width: 64px;
  height: 64px;
  max-width: 64px;
  max-height: 64px;
  flex-shrink: 0;
}

.job-logo img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.post-details {
  display: flex;
  flex-direction: column;
}
.post-company {
  color: #141414;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 27px */
}
.post-position {
  color: #141414;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  padding-top: 4px;
}
.post-specs {
  display: flex;
  padding: 8px 0px;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
}

.post-specs div {
  color: rgba(20, 20, 20, 0.7);
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 23%;
}

.post-specs div span {
  padding-right: 6px;
  font-size: 14px;
}

.post-description {
  color: rgba(20, 20, 20, 0.7);
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding-top: 14px;
}

.total-jobs {
  padding: 5%;
  color: #141414;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  /* line-height: 150%; */
  /* 48px */
}

.filter-heading {
  color: #141414;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  /* 30px */
}

.filters-cols {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 16px;
}

.filter-container-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.filter-name {
  color: #141414;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  text-align: left;
}

.email-input-wrapper {
  margin: 16px 0px;
}

.email-input-container {
  border-radius: 4px;
  border: 1px solid rgba(20, 20, 20, 0.2);
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
  padding: 8px 12px;
}

.email-input-container input {
  flex: 1;
  border: none;
  outline: none;
  padding: 0;
  color: rgba(20, 20, 20, 0.5);
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.large-primar-btn {
  display: flex;
  padding: 8px 20px 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: #3575e2;
  color: var(--White, #fff);
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 24px */
  border: none;  /* Remove default border */
  outline: none;  /* Remove focus outline (add custom styles for accessibility!) */
  cursor: pointer;  /* Hand cursor for button */
  width: 100%;  /* Make it take full width of the container */

}

.mt-16 {
  margin-top: 16px;
}

/* .job-list-pagination-container {
  padding-top: 32px;
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr 0.5fr 1fr 1fr;
  margin: auto;
} */

.page-wrapper {
  border-radius: 2px;
  border: 1px solid rgba(17, 17, 17, 0.1);
  background: #fff;
  display: flex;
  width: 36px;
  height: 36px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

.page-wrapper:active {
  scale: 0.9;
  transition: all 0.3s;
}

.page-dot-wrapper {
  border-radius: 2px;
  background: #fff;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 4px;
}

.chips-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.chip {
  background-color: #e7ecef;
  color: #141414;
  border: 1px solid rgba(17, 17, 17, 0.1);
  border-radius: 8px;
  padding: 2.5px 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.chip.selected {
  background-color: #0077ff;
  border: 1px solid #0077ff;
  color: white;
}

.view-more {
  color: rgba(17, 17, 17, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.filter-toggle-button {
  display: none;
  /* Hide the button by default in desktop view */
}

.view-jobs{
  display: none;
}

.jobs-panel-wrapper {
  display: flex;
  justify-content: space-between;
}

/* CSS for Mobile View */
@media (max-width: 768px) {

  .home-page {
    padding: 2%;
  }

  .jobs-panel-wrapper {
    padding-top: 24px;
  }
  .headline-container {
    padding: 48px 0px 8px;
  }
 
  /* Adjust the grid layout for the main container */
  .main-section-container {
    grid-template-columns: 1fr;
    /* gap: 16px; */
    padding: 0;
  }

  .filter-container {
    padding: 4px 0px;
  }
  /* Modify the styles for the common filters container */
  /* .common-filters-wrapper {
    padding: 16px;
  } */

  /* .common-filters-container {
    display: none;
  } */

  /* Adjust the grid layout for the jobs list container */
  .jobs-list-container {
    grid-template-rows: auto;
  }

  /* Modify the styles for job card containers */
  .job-card-container {
    flex-direction: column;
    gap: 16px;
    /* padding: 24px 16px 16px; */
  }

  /* Adjust the layout for email and subscription containers */
  .email-container {
    gap: 16px;
  }

  /* Modify the styles for subscription and resume containers */
  .subscription-container,
  .resume-container {
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;  /* if you want vertical centering as well */
  
  }

  /* Adjust the pagination container layout */
  /* .job-list-pagination-container {
    grid-template-columns: 1fr 1fr;
  } */

  /* Modify the styles for the search and filter section */
  .filter-section {
    flex-direction: column;
    gap: 10px;
  }

  .location-filter,
  .filter-submit {
    width: auto;
    border-radius: 4px;
    margin-top: 16px;
  }

  .filter-wrapper {
    grid-template-columns: 1fr;
  }


  .post-specs {
    display: grid;
    /* Use grid layout */
    grid-template-columns: 60% 40%;
    /* Two columns with equal width */
    gap: 10px;
    /* Add spacing between columns */
  }

  .post-specs div {
    max-width: 100%;
    /* Allow elements to take full width within each grid cell */
  }

  .post-specs div span {
    padding-right: 6px;
    /* Restore padding on the right of icons */
  }

  /* .job-list-pagination-container {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  } 
  */

  .page-wrapper {
    padding: 8px 12px;
    /* Adjust padding for buttons */
  }

  /* Hide the page-dot-wrapper and other buttons */
  .page-dot-wrapper,
  .page-wrapper:nth-child(n + 2):not(:last-child) {
    display: none;
  }

  /* Hide the "Partition Circle" */
  .partition-circle {
    display: none !important;
  }

  .filter-toggle-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 10px;
    margin-right: 5%;
    border: 1px solid #eee;
    border-radius: 4px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .filter-toggle-button button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: rgba(17, 17, 17, 0.5);
  }

  .filter-toggle-button .filter-icon {
    color: rgba(17, 17, 17, 0.5);
    margin-right: 8px;
  }

  .search-box {
    margin-top: 2%;
    margin-bottom: 5%;
    display: flex;
    justify-content: center;
  }
  


* {
  box-sizing: border-box;
}

}
* {
  box-sizing: border-box;
}
