/* MetricsBox.css */

.metrics-box {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .metric-item {
    background: #f6f6f6;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 0 5px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
  }
  
  .metric-icon {
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }
  
  .metric-count {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-right: 5px;
  }
  
  .metric-label {
    font-size: 12px;
    color: #777;
  }
  