.job-card {
    background: transparent;
    font-family: 'DM Sans', sans-serif;
    border-bottom: 1px solid #e8e8e8;
    transition: background-color 0.3s ease;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* ease transition for smooth effect */
    position: relative; /* set position */

}

.company-logo {
    width: 250px;
    height: 250px;
    object-fit: contain;
    display: block;
}
.job-title2 {
    font-weight: 600;
    margin-right: 12px;

}

.company-details {
    display: inline-block;
    margin-top: 2px;
}

.experience-details {
    font-weight: 400;
    color: #444;
    margin-bottom: 6px;
}

.job-link {
    display: flex;
    justify-content: space-between;
    padding: 8px 12px;
    text-decoration: none;
    color: inherit;
    align-items: center;
    width: 100%;
}

.job-details2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    margin-left: 10px;
}

.job-link:hover {
    background-color: #f5f5f5;
    border-radius: 4px; /* added for smoother hover effect */
}

.job-link span {
    display: block;
    margin-bottom: 4px; /* adding some space between the text elements */
}

.job-link .company-name {
    font-weight: bold;
    color: #333;
}

.posted-date {
    color: #777;
    font-size: 0.9rem;
}

.job-location {
    font-weight: 400;
    color: #666;
    display: inline;
    padding-left: 0.3%;
    color: #333333;

}

.company-name {
    font-weight: 500; 
    color: #444;
}

.job-info-line {
    display: flex;
    align-items: center;
}



.job-card {
    background-color: transparent;  /* white background for the card */
    font-family: 'DM Sans', sans-serif;
    border-bottom: 1px solid #e8e8e8;
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    padding: 12px 16px;
    display: flex;
    align-items: center;
}

.company-logo {
    width: 150px;
    height: 90px;
    object-fit: contain;
    display: block;
    margin-right: 16px; /* Add some space between logo and content */
}

.job-card:hover {
    background-color: #f5f5f5;
    transform: translateY(-3px); /* reduced for subtle effect */
    box-shadow: 0 8px 16px rgba(0,0,0,0.1);
}


/* Responsive CSS */
@media (max-width: 600px) {
    .job-card {
        background: linear-gradient(to bottom, #f9f9f9, #e9e9e9);
        box-shadow: 0 2px 10px rgba(0,0,0,0.05);
    }

    .job-card:hover {
        transform: translateY(-5px);
        box-shadow: 0 10px 20px rgba(0,0,0,0.1);
    }

    .company-logo {
        align-self: center;
        width: 120px;
        height: 80px;
        margin: 0 auto; /* To ensure the logo is centered */
    }

    .job-link {
        flex-direction: column;
        align-items: center; /* this ensures that the items are center-aligned */
        padding: 10px 15px;
        text-align: center;
    }

    .job-details2 {
        align-items: center; /* ensure that items inside this container are center-aligned */
        padding: 10px 15px;

    }

    .job-location {
        font-weight: 400;
        color: #666;
        display: flex;
        justify-content: center; /* center the location text and icon */
        padding: 5%;
    }

    .job-location svg {
        margin-right: 10px; /* Adjust this value based on how much space you want */
        vertical-align: middle;
    }

}


