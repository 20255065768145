.pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: 20px 0;
}


.pagination button {
    padding: 10px 20px; /* increased padding for larger touch targets and aesthetics */
    border: none;
    border-radius: 5px; /* slightly more rounded corners */
    background-color: #007bff;
    color: white;
    font-weight: 600; /* bolder font */
    cursor: pointer;
    transition: all 0.2s; /* smooth transition for all properties */
    box-shadow: 0px 4px 6px rgba(0,0,0,0.1); /* subtle shadow for depth */
    margin-left: auto;
    margin-right: auto;

}

.pagination button:hover {
    background-color: #0056b3;
    transform: translateY(-2px); /* subtle lift on hover */
    box-shadow: 0px 5px 9px rgba(0,0,0,0.12); /* increased shadow on hover for more depth */
}

.pagination button:disabled {
    background-color: rgba(0,123,255,0.4); /* slightly faded for disabled state instead of hiding */
    cursor: not-allowed; /* indicates the button is disabled */
    box-shadow: none; /* remove shadow for disabled state */
}
