.footer-container {
    font-family: 'Roboto', sans-serif; /* Add a professional font */
    background-color: #fff; /* Light background color for differentiation */
    text-align: center;
    padding: 20px 0;
    color: #fff;
}

.footer-divider {
    margin: 0 auto 20px auto;
    max-width: 80%;
    border: 0;
    border-top: 1px solid #ddd;
}

.footer-links {
    margin-bottom: 20px;
}

.footer-links a {
    margin: 0 15px;
    text-decoration: none;
    color: #007bff;
    transition: color 0.2s ease-in-out;
    padding: 5px 10px; /* Adding padding for better hover effect */
    border-radius: 3px; /* Rounded corners for hover effect */
}

.footer-links a:hover {
    color: #fff; /* Changing text color to white on hover */
    background-color: #007bff; /* Background color on hover */
}

.footer-links span {
    margin: 0 5px;
    color: #888; /* Making the pipe a little bit lighter for a softer look */
}

/* Mobile View */
@media (max-width: 768px) {
    .footer-links a, .footer-links span {
        display: block;
        margin: 10px 0;
    }

    .footer-links span {
        display: none;  /* Hide the pipes on mobile */
    }
}
