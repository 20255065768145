.error-message {
  color: red;
  margin-top: 8px;
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.resume-container {
  /* padding: 20px; */
  background-color: #f9f9f9;
  /* border-radius: 5px; */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  text-align: center;
}

.filter-heading {
  font-size: 1.4rem; /* Increased font size */
  color: #333;
  margin-bottom: 10px;
  font-weight: bold; /* Made the heading bold */
}

.post-description {
  font-size: 1.1rem; /* Slightly increased font size */
  color: #555; /* Darkened the text for better readability */
  margin-bottom: 20px;
}

.large-primar-btn {
  display: flex;
  /* padding: 10px 25px; Increased padding */
  justify-content: center;
  align-items: center;
  /* gap: 8px; */
  /* border-radius: 4px; */
  background: #3575e2;
  color: #fff;
  font-family: DM Sans;
  font-size: 1rem;
  font-weight: 300;
  line-height: 150%;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
  width: 100%;
  /* margin-top: 1rem; */
}

.large-primar-btn:hover {
  background-color: #2860c0;
}

.mt-16 {
  margin-top: 16px;
}

* {
  box-sizing: border-box;
}


@media (max-width: 768px) 
{
  .resume-container {
    margin: 0 auto;
    text-align: center;
}

}

.selected-file-name {
  color: #555;
  font-size: 0.9rem;
  margin-top: 10px;
  font-style: italic;
}
